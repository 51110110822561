<template>
<page :data="pageData" :is-child="false">
    <template #cmsleftcomponents>
      <form @submit="submit" v-if="formType">
        <div class="w-full justify-start flex mr-4 mb-2 underline text-red-300">
          <div class="cursor-pointer" @click="formType = ''">Back to List</div>
        </div>
        <b-field>
          <b-input v-model="form.ItemName" placeholder="Nama Produk" required></b-input>
        </b-field>
        <b-field v-if="isForm">
          <upload-button @upload="setFile">Upload</upload-button>
        </b-field>
        <b-field v-if="!isForm">
          <b-input v-model="form.ProductURL" placeholder="URL Produk" required></b-input>
        </b-field>
        <b-field v-if="isForm">
          <money-input v-model="form.ItemValue" placeholder="Nilai Produk" :show-symbol="false" required></money-input>
        </b-field>
        <b-field>
          <b-input v-model="form.Quantity" placeholder="Kuantiti" inputmode="numeric" type="number" required></b-input>
        </b-field>
        <b-field>
          <b-input v-model="form.Description" placeholder="Selengkapnya" type="textarea"></b-input>
        </b-field>
        <div>
          <b-button :type="isForm && form && !form.File ? 'is-light' : 'is-primary'" class="w-full" :native-type="isForm && form && !form.File ? undefined : submit">TAMBAH</b-button>
        </div>
      </form>
      <div v-else>
        <list-item :data="{ Title: 'List Daftar Barang', TitleClass: 'text-2xl', BorderWidth: 0 }">
          <template #righttitle>
            <b-button type="is-primary" class="mr-4" @click="formType = 'form'">+Form</b-button>
            <b-button type="is-primary" @click="formType = 'link'">+Link</b-button>
          </template>
        </list-item>
        <div v-for="(item, index) in items" :key="index">
          <div @click="deleteItem(item)" class="mt-2"><b-icon icon="delete" class="bg-gray-100 border border-solid rounded-full cursor-pointer p-4 my-auto hover:border-gray-600" custom-size="mdi-24px" type="is-primary"></b-icon></div>
          <list-item :data="{ Title: 'Barang', RightTitle: item.ItemName, BorderWidth: 0 }"></list-item>
          <list-item :data="{ Title: 'Quantity', RightTitle: item.Quantity, BorderWidth: 0 }"></list-item>
          <list-item :data="{ Title: 'Harga Barang', RightTitle: item.ItemValue, BorderWidth: 0 }"></list-item>
          <!-- <list-item :data="{ Title: 'Ongkir', RightTitle: 0, BorderWidth: 0 }"></list-item>
          <list-item :data="{ Title: 'JASTIP Fee', RightTitle: 0, BorderWidth: 0 }"></list-item> -->
          <!-- <list-item :data="{ Title: 'Total', RightTitle: 0, RightTextClass: 'font-bold' }"></list-item> -->
          <list-item :data="{ Title: '', RightTitle: 0, RightTextClass: 'font-bold' }"></list-item>
        </div>
        <div>
          <b-button type="is-primary" class="w-full" @click="openDialog" :disabled="items.length <= 0">PESAN</b-button>
        </div>
      </div>
    </template>
</page>
</template>

<script>
import Page from '@/components/vaddress/cmspages/Page'
import MoneyInput from '@/components/vaddress/form/Money'
import ListItem from '@/components/vaddress/ListItem'
import UploadButton from '@/components/vaddress/UploadButton'
const pageData = {
  config: {
    Header: {
      Title: 'Jastip'
    }
  },
  cmsLeft: {
    Data: []
  }
}

const initialFormValues = {
  Oid: null,
  ItemName: '',
  ProductURL: '',
  ItemValue: '',
  Quantity: '',
  Description: '',
  Instructions: '',
  File: null,
  Extension: null,
  Size: null
}

export default {
  components: { Page, MoneyInput, ListItem, UploadButton },
  props: {
    initialFormType: {
      type: String,
      required: false
    }
  },
  mounted () {
    this.formType = this.$route.query.initial_form_type
  },
  data () {
    return {
      items: [],
      form: {
        ...initialFormValues
      },
      formType: ''
    }
  },
  methods: {
    deleteItem (e) {
      var temp = this.items.filter(a => {
        return a !== e
      })
      this.items = temp
      if (this.items.length === 0) this.formType = true
    },
    openDialog () {
        this.$buefy.dialog.alert({
            title: 'Pesanan Anda diterima',
            message: 'Admin kami akan segera menghubungi Anda dalam 1-2 Hari kerja',
            confirmText: 'Ok',
            onConfirm: () => this.order()
        })
    },
    submit () {
      this.items.push(this.form)
      this.formType = ''
    },
    async order () {
      const loading = this.$buefy.loading.open()
      try {
        await this.$baseApi.post(
          '/user/orders/createproxy',
          this.items
        )
        // await Promise.all(this.items.map(async ({ _file, _imageURL, ...item }) => {
        //   const { Oid } = await this.$baseApi.post(
        //     '/user/orders/createproxy',
        //     [item]
        //   )
        //   if (_file) {
        //     const url = await this.uploadImageS3(_file)
        //     await this.saveServerImage(Oid, url, _file)
        //   }
        // }))p
        this.$router.back()
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    setFile (file) {
      console.log(file)
      Object.assign(this.form, file)
    }
  },
  computed: {
      pageData () { return pageData },
      isForm () { return this.formType !== 'link' }
  },
  watch: {
    formType () {
      this.form = { ...initialFormValues }
    }
  }
}
</script>

<style>

</style>
