<template>
  <div class="flex justify-center items-center">
    <p class="control" v-if="showSymbol">
      <span class="button is-static" style="border-right-width: 0; border-top-right-radius: 0; border-bottom-right-radius: 0">{{userCurrency.Symbol}}</span>
    </p>
    <b-input style="styles" inputmode="numeric" :placeholder="placeholder" :value="getMoneyDisplayValue(value)" @input="e => input(getMoneyValue(e))" :class="['w-full']" @blur="$emit('blur')" />
    <input type="hidden" :name="name" :value="value" />
  </div>
</template>

<script>
import { formatCurrency } from '../../../filters/number'
export default {
  props: {
    name: String,
    value: String,
    decimal: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      required: false
    },
    currency: {
      type: Object,
      required: false
    },
    showSymbol: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    input (val) {
      this.$emit('input', val)
    },
    getMoneyDisplayValue (v) {
      const value = formatCurrency(v, this.userCurrency)
      if (!value) return ''
      return value.replace(this.userCurrency.Symbol, '')
    },
    getMoneyValue (v) {
      const value = v.replace(this.userCurrency.Symbol, '')
        .replace(/\./g, '')
        .replace(/,/g, '')
        .replace(/ /g, '')
        console.log(value)
      return value
    }
  },
  computed: {
    styles () {
      if (this.showSymbol) {
        return 'border-left-width: 0; border-top-left-radius: 0; border-bottom-left-radius: 0'
      }
      return ''
    },
    userCurrency () {
      return this.currency || (this.user && this.user.CurrencyObj) || { Code: 'IDR', Symbol: 'Rp', Decimal: 0 }
    }
  }
}
</script>
